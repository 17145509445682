<template>
  <div class="field has-addons">
    <div class="control is-expanded">
      <input
        class="input"
        v-model.trim="value"
        type="text"
        placeholder="Search by VRM"
        name="value"
        v-validate.disable="{
          required: true,
          min: 4,
          regex: /^[a-zA-Z0-9]*$/
        }"
        @keyup.enter="setFilterState"
      />
    </div>
    <div class="control">
      <button class="button is-soft" @click="setFilterState">
        <span class="icon">
          <i class="fal fa-search"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StockviewSearch',
  data: () => ({
    value: ''
  }),
  watch: {
    value: 'formatValue'
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters'])
  },
  methods: {
    ...mapActions('stockview/listings', ['updateFilters']),
    formatValue(val) {
      this.value = val.replace(' ', '').toUpperCase()
      if (val === '') this.setFilterState()
    },
    track() {
      this.$mxp.track('stockview_stock_vrm_search')
    },
    async setFilterState() {
      const valid = await this.$validator.validate()
      if (!valid && this.value) {
        const rule = this.$validator.errors.items[0].rule
        const message =
          rule === 'regex'
            ? 'Please enter a VRM with alphanumeric values only'
            : 'Please enter a VRM with at least 4 characters'
        this.$notify(message)
      } else {
        const type = 'vrm'
        const count = this.value ? 1 : 0
        const payload = count ? this.value : null
        this.updateFilters({ filters: [{ type, payload }], count, type })
        if (this.value) this.track()
      }
    }
  }
}
</script>
